import { Routes } from '@angular/router';
import { AccessGuard } from '../guard/access.guard';
import { AccessRightEnum } from '../core/enums/access-right.enum';
import { GameGuard } from '../guard/game.guard';
import { MeaAccessGuard } from '../guard/mea-access.guard';

export enum NavigationRoutesEnum {
    dashboard = '/app/dashboard',
    mea = '/app/mea',
    logistics = '/app/logistics',
    offers = '/app/offers',
    calendar = '/app/calendar',
    seminars = '/app/seminars',
    statistics = '/app/statistics',
    knowledge = '/app/knowledge',
    meamind = '/app/knowledge/meamind',
    invoicePage = '/app/logistics/invoices',
    archivePage = '/app/logistics/archive',
    news = '/app/news',
    settings = '/app/settings',
    information = '/app/information',
    sanavendi = '/app/sanavendi',
    communications = '/app/communications',
    santaGame = '/app/santa',
    easterGame = 'app/fun',
    representatives = '/app/representatives',
}

export const childrenRoutes: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('../pages/dashboard/dashboard.module').then((m) => m.DashboardPageModule),
    },
    {
        path: 'mea',
        loadChildren: () => import('../pages/mea/mea.module').then((m) => m.MeaPageModule),
        canActivate: [MeaAccessGuard],
    },
    {
        path: 'logistics',
        loadChildren: () => import('../pages/logistics/logistics.module').then((m) => m.LogisticsModule),
    },
    {
        path: 'offers',
        loadChildren: () => import('../pages/offers/offers.module').then((m) => m.OffersPageModule),
    },
    {
        path: 'calendar',
        loadChildren: () => import('../pages/calendar/calendar.module').then((m) => m.CalendarPageModule),
    },
    {
        path: 'seminars',
        loadChildren: () => import('../pages//seminars/seminars.module').then((m) => m.SeminarsPageModule),
    },
    {
        path: 'statistics',
        loadChildren: () => import('../pages/statistics/statistics.module').then((m) => m.StatisticsPageModule),
    },
    {
        path: 'meamind', // this is necessary for the redirectUri for meamind - do not use it anywhere else
        loadChildren: () => import('../pages/knowledge/knowledge.module').then((m) => m.KnowledgePageModule),
    },
    {
        path: 'knowledge',
        loadChildren: () => import('../pages/knowledge/knowledge.module').then((m) => m.KnowledgePageModule),
    },
    {
        path: 'news',
        loadChildren: () => import('../pages/news/news.module').then((m) => m.NewsPageModule),
    },
    {
        path: 'settings',
        loadChildren: () => import('../pages/settings/settings.module').then((m) => m.SettingsPageModule),
    },
    {
        path: 'information',
        loadChildren: () => import('../pages/information/information.module').then((m) => m.InformationPageModule),
    },
    {
        path: 'sanavendi',
        loadChildren: () => import('../pages/sanavendi/sanavendi.module').then((m) => m.SanavendiPageModule),
        canActivate: [AccessGuard],
        data: { accessRight: AccessRightEnum.sanavendi },
    },
    {
        path: 'communications',
        loadChildren: () => import('../pages/communications/communications.module').then((m) => m.CommunicationsPageModule),
    },
    {
        path: 'santa',
        loadChildren: () => import('../pages/game/game.module').then((m) => m.GamePageModule),
        canActivate: [GameGuard],
        data: { event: 'christmas' },
    },
    {
        path: 'no-access',
        loadChildren: () => import('../pages/common/no-access/no-access.module').then((m) => m.NoAccessPageModule),
    },
    {
        path: 'fun',
        loadChildren: () => import('../pages/fun/fun.module').then((m) => m.FunModule),
        canActivate: [GameGuard],
        data: { event: 'easter' },
    },
    {
        path: 'representatives',
        loadChildren: () => import('../pages/representatives/representatives.module').then((m) => m.RepresentativesModule),
    },
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
];
